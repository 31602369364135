<template>
  <div class="perdu" ref="perdu" tabindex="-1" @keyup.space="avancer">
    <transition name="fade">
      <div class="deso" v-if="afficher">
        <h1>Désolé !</h1>
        <p>Vous n'avez pas gagné. Retentez votre chance une prochaine fois</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Perdu",
  data() {
    return {
      afficher: false,
      lot: false,
    };
  },
  mounted() {
    this.$refs.perdu.focus();
    setTimeout(() => {
      this.afficher = true;
      ion.sound.play("buzz");
    }, 500);
  },
  methods: {
    avancer() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
.perdu {
  position: relative;
  .deso {
    text-align: center;
    position: absolute;
    top: 5%;
    left: 50%;
    color: white;
    text-transform: uppercase;
    h1 {
      font-weight: bold;
      font-size: 11vh;
    }
    p {
      font-size: 5vh;
    }
    transform: translateX(-50%);
  }
}
</style>